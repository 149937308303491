const constants = {
  // css constants
  black: "#212121",
  blackHovered: "#616161",
  white: "#ffffff",
  whiteHovered: "#424242",
  red: "#f44336",
  primaryRed: "#E72953",
  offWhite: "#bdbdbd",
  blue: "#29b6f6",
  blueHovered: "#039be5",
  primaryRed: "#E72953",
  red: "#f44336",
  redHovered: "#d50000",
  green: "#66bb6a",
  greenHovered: "#43a047",
  gray: "#4F5266",
  lightGray: "#B9B9B9",
  whiteHoveredLight: "#e0e0e0",
  //
  //serverUrl: "https://tkns-server.herokuapp.com",
  // serverUrl: "https://tkns-staff-beta.herokuapp.com",
   //serverUrl: "http://localhost:8080",
   serverUrl: "https://gameside-server-production.up.railway.app",
  //clientUrl: "https://tkns.gg",
  // clientUrl: "https://fntokens.gg",
  //clientUrl: "https://gameside.cc",
   clientUrl: "https://www.gameside.cc",
  //clientUrl: "http://localhost:3000",
  // clientUrl: "https://battlewiseclient.z22.web.core.windows.net/",
  // serverUrl: "https://battlewise-server.herokuapp.com",
  // new colors
  newBlue: "#40c9ff",
  newOrange: "#ff9100",
  newGray: "#564f4f",
  newGreen: "#6ede8a",
  newBorderRadius:
    "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
  newGrayLight: "#ADAEB6",
  newOffWhite: "##F2F3F8",
};
export default constants;
